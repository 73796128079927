import { useGetPatient } from '@lib/features-bll';
import { Modal, StatusCard } from '@lib/react-components';
import { Stack, TextField } from '@mui/material';
import { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { IUploadFileModalProps } from './types';

import { useActiveProfileContext } from 'features/ActiveProfile';
import { useDoctorSetPatientUserEmail } from 'features/Doctor/graphql/__generated__/DoctorSetPatientUserEmail.mutation';
import { usePortalConfiguration } from 'features/Portal';
import { i18n } from 'i18n';

export const UploadFileModal = ({ children, footer, ...otherProps }: IUploadFileModalProps): JSX.Element => {
  const {
    params: { patientId },
  } = useRouteMatch<{ patientId: string }>();
  const [email, setEmail] = useState('');
  const configuration = usePortalConfiguration();
  const { isPatient } = useActiveProfileContext();
  const [updateUserEmail] = useDoctorSetPatientUserEmail({
    variables: { email, patientId: patientId || '' },
  });
  const { data: patientData } = useGetPatient({
    variables: {
      patientId,
    },
    skip: isPatient || !patientId,
  });
  const isEmailVisible =
    configuration?.isPatientSelfRegistrationEnabled && patientData && !patientData.patient.user.email;

  const isPatientSelfRegistrationAndNotActiveProfile = configuration?.isPatientSelfRegistrationEnabled && !isPatient;

  const statusCardProps = {
    status: 'info',
    text: isEmailVisible
      ? i18n.t(`features.doctor.emailExtensionModal.emailInfo`)
      : i18n.t(`features.doctor.emailExtensionModal.notificationInfo`),
  };

  return (
    <Modal footer={footer(isEmailVisible && email && patientId ? updateUserEmail : undefined)} {...otherProps}>
      {children}
      {patientId && (
        <Stack gap="50px">
          {isPatientSelfRegistrationAndNotActiveProfile && <StatusCard {...statusCardProps} />}
          {isEmailVisible && (
            <TextField
              name="email"
              placeholder={i18n.t('features.doctor.emailExtensionModal.email')}
              variant="standard"
              onChange={({ target: { value } }): void => setEmail(value)}
              value={email}
            />
          )}
        </Stack>
      )}
    </Modal>
  );
};
