import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type PdfParametersExtractedVariables = Types.Exact<{
  context: Types.SubscriptionContextModel;
}>;


export type PdfParametersExtracted = { __typename?: 'Subscription', pdfParametersExtracted: { __typename?: 'FileModel', id: string, hasAnyPdfParametersRevision: boolean, tag?: Types.FileTag | null, pdfHeaderExtracted: boolean, pdfParametersRevision?: { __typename?: 'PdfParametersRevisionModel', completionPercent: number, lastUpdatedAt?: string | null } | null } };


export const PdfParametersExtractedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"PdfParametersExtracted"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"context"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SubscriptionContextModel"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pdfParametersExtracted"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"context"},"value":{"kind":"Variable","name":{"kind":"Name","value":"context"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"hasAnyPdfParametersRevision"}},{"kind":"Field","name":{"kind":"Name","value":"tag"}},{"kind":"Field","name":{"kind":"Name","value":"pdfHeaderExtracted"}},{"kind":"Field","name":{"kind":"Name","value":"pdfParametersRevision"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"completionPercent"}},{"kind":"Field","name":{"kind":"Name","value":"lastUpdatedAt"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __usePdfParametersExtracted__
 *
 * To run a query within a React component, call `usePdfParametersExtracted` and pass it any options that fit your needs.
 * When your component renders, `usePdfParametersExtracted` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePdfParametersExtracted({
 *   variables: {
 *      context: // value for 'context'
 *   },
 * });
 */
export function usePdfParametersExtracted(baseOptions: ApolloReactHooks.SubscriptionHookOptions<PdfParametersExtracted, PdfParametersExtractedVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<PdfParametersExtracted, PdfParametersExtractedVariables>(PdfParametersExtractedDocument, options);
      }
export type PdfParametersExtractedHookResult = ReturnType<typeof usePdfParametersExtracted>;
export type PdfParametersExtractedSubscriptionResult = Apollo.SubscriptionResult<PdfParametersExtracted>;