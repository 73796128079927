import { convertStringToNumber, useExecuteOnTimeout } from '@lib/utils';

import config from '../../../../../../../config.json';
import { AiLoadingModal } from '../AiLoadingModal';

import { attachmentsModel } from 'features/Attachments/model';

interface IAiHeaderDataExtractionLoadingModalProps {
  onFinish: VoidFunction;
}

export const AiHeaderDataExtractionLoadingModal = ({
  onFinish,
}: IAiHeaderDataExtractionLoadingModalProps): JSX.Element => {
  attachmentsModel.useAiHeaderExtractionSubscription(onFinish);

  useExecuteOnTimeout(onFinish, convertStringToNumber(config.AiHeaderExtractionTimeout));

  return <AiLoadingModal />;
};
