import { useUpdateFileAttributes } from '@lib/features-bll';
import { getIsoFormatUTCDate } from '@lib/utils';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IUploadFilesProps } from '../UploadFiles';

import { useActiveProfileContext } from 'features/ActiveProfile';
import { attachmentsModel, useAttachmentsStore } from 'features/Attachments/model';
import { IChatFile, useChatModelChatFilesStore } from 'features/Chat/model';
import { useUserModelStore } from 'features/Users/model';
import { IFile } from 'graphql/types/files';
import { IIntramedFile } from 'graphql/types/intramed';
import { IServiceAttachmentFile } from 'graphql/types/request';
import { getAttachmentsToUpload } from 'store/attachments/selectors';
import { addIntramedFile } from 'store/intramed/actionCreators';

type UseConfirmAttachmentsProps = Pick<
  IUploadFilesProps,
  'attachToIntramed' | 'attachToRequest' | 'attachToChat' | 'patientId' | 'threadId' | 'loadingAction' | 'preSaveAction'
>;

interface IUseConfirmAttachmentsReturn {
  confirmAttachments: () => Promise<void>;
  isConfirmAttachmentsLoading: boolean;
}

type UseConfirmAttachments = (props: UseConfirmAttachmentsProps) => IUseConfirmAttachmentsReturn;

export const useConfirmAttachments: UseConfirmAttachments = ({
  attachToRequest,
  attachToIntramed,
  attachToChat,
  loadingAction,
  threadId,
  patientId,
  preSaveAction,
}) => {
  const { addAttachments } = useAttachmentsStore();
  const { addChatFile } = useChatModelChatFilesStore();
  const dispatch = useDispatch();
  const { userInfo } = useUserModelStore();
  const { activeProfile } = useActiveProfileContext();
  const attachmentsToUpload = useSelector(getAttachmentsToUpload);
  const [updateFileAttributes] = useUpdateFileAttributes();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const attachPatientFile = attachmentsModel.useAttachPatientFile();

  const confirmAttachments: IUseConfirmAttachmentsReturn['confirmAttachments'] = async () => {
    if (preSaveAction) {
      await preSaveAction();
    }

    if (!userInfo) {
      return;
    }

    const uploaderInstitutionUser = {
      id: activeProfile?.id,
      profileType: activeProfile?.profileType,
      user: {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
      },
    };

    attachmentsToUpload?.map(async attachment => {
      loadingAction?.(true);
      setIsLoading(true);

      const clientMetadata = JSON.stringify({
        suggestedValues: attachment.aiSuggestions,
        confirmedValues: {
          label: attachment.label,
          tag: attachment.fileTag,
          dateOfExecution: attachment.dateOfExecution,
        },
      });

      const { data } = await updateFileAttributes({
        variables: {
          fileId: attachment.fileId,
          label: attachment.label,
          dateOfExecution: attachment.dateOfExecution ? getIsoFormatUTCDate(attachment.dateOfExecution) : null,
          fileTag: attachment.fileTag,
          clientMetadata,
        },
      });

      if (data) {
        const { id, dateOfExecution, label } = data.updateFileAttributes;

        if (attachToIntramed) {
          dispatch(
            addIntramedFile({
              file: {
                id,
                dateOfExecution,
                label,
                tag: attachment.fileTag,
                type: attachment.fileType,
                uploaderInstitutionUser,
              } as IFile,
            } as IIntramedFile)
          );
        }

        if (attachToChat) {
          addChatFile({
            file: {
              id,
              dateOfExecution,
              label,
              tag: attachment.fileTag,
              type: attachment.fileType,
              uploaderInstitutionUser,
            } as IFile,
          } as IChatFile);
        }

        if (patientId && !(attachToRequest || attachToIntramed || attachToChat)) {
          attachPatientFile({
            variables: {
              fileId: id,
              patientId,
            },
          });
        }

        if (attachToRequest) {
          addAttachments([
            {
              threadId,
              id,
              file: {
                id,
                dateOfExecution,
                label,
                tag: attachment.fileTag,
                type: attachment.fileType,
                uploaderInstitutionUser,
              } as IFile,
            } as IServiceAttachmentFile,
          ]);
        }
      }

      loadingAction?.(false);
      setIsLoading(false);
    });
  };

  return {
    isConfirmAttachmentsLoading: isLoading,
    confirmAttachments,
  };
};
