export enum ChatMenuItemType {
  EmitReport = 'EmitReport',
  EmitPrescription = 'EmitPrescription',
  SelectFromClinicalFolder = 'SelectFromClinicalFolder',
  UploadNewFile = 'UploadNewFile',
  UploadDICOM = 'UploadDICOM',
  ScanDocument = 'ScanDocument',
  UploadDocuments = 'UploadDocuments',
  UploadFoto = 'UploadFoto',
}
