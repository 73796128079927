import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type PdfHeaderExtractedVariables = Types.Exact<{
  context: Types.SubscriptionContextModel;
}>;


export type PdfHeaderExtracted = { __typename?: 'Subscription', pdfHeaderExtracted: { __typename?: 'PdfHeaderExtractionResultModel', fileId: string, fileTag?: Types.FileTag | null, patientName?: string | null, matchesActualPatientName: boolean, executionDate?: string | null } };


export const PdfHeaderExtractedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"PdfHeaderExtracted"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"context"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SubscriptionContextModel"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pdfHeaderExtracted"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"context"},"value":{"kind":"Variable","name":{"kind":"Name","value":"context"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fileId"}},{"kind":"Field","name":{"kind":"Name","value":"fileTag"}},{"kind":"Field","name":{"kind":"Name","value":"patientName"}},{"kind":"Field","name":{"kind":"Name","value":"matchesActualPatientName"}},{"kind":"Field","name":{"kind":"Name","value":"executionDate"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __usePdfHeaderExtracted__
 *
 * To run a query within a React component, call `usePdfHeaderExtracted` and pass it any options that fit your needs.
 * When your component renders, `usePdfHeaderExtracted` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePdfHeaderExtracted({
 *   variables: {
 *      context: // value for 'context'
 *   },
 * });
 */
export function usePdfHeaderExtracted(baseOptions: ApolloReactHooks.SubscriptionHookOptions<PdfHeaderExtracted, PdfHeaderExtractedVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<PdfHeaderExtracted, PdfHeaderExtractedVariables>(PdfHeaderExtractedDocument, options);
      }
export type PdfHeaderExtractedHookResult = ReturnType<typeof usePdfHeaderExtracted>;
export type PdfHeaderExtractedSubscriptionResult = Apollo.SubscriptionResult<PdfHeaderExtracted>;