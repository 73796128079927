import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { SecureDatabase } from './MedicalDB';

import { Maybe } from '__generated__/types';
import { useUserModelStore } from 'features/Users/model';

interface Draft {
  patientId: string;
  text: string;
  createdAt: Date;
}

interface MedicalDBContextType {
  drafts: Record<string, Draft>;
  getDraft: (patientId: string) => Promise<Maybe<Draft>>;
  saveDraft: (draft: Draft) => Promise<void>;
  loadDrafts: (patientId: string) => Promise<void>;
  deleteDraft: (patientId: string) => Promise<void>;
}

const MedicalDBContext = createContext<MedicalDBContextType>({
  drafts: {},
  getDraft: async () => null,
  saveDraft: async () => {},
  loadDrafts: async () => {},
  deleteDraft: async () => {},
});

interface IMedicalDBProviderProps {
  children: ReactNode;
}

export const MedicalDBProvider = ({ children }: IMedicalDBProviderProps): JSX.Element => {
  const [drafts, setDrafts] = useState<Record<string, Draft>>({});

  let draftsTable: SecureDatabase | undefined;

  const { userInfo } = useUserModelStore();

  const getDraft = useCallback(
    async (patientId: string): Promise<Maybe<Draft>> => {
      const draft = await draftsTable?.get(patientId);

      return draft
        ? {
            patientId: draft.patientId,
            text: draft.text,
            createdAt: draft.createdAt,
          }
        : null;
    },
    [draftsTable]
  );

  const loadDrafts = useCallback(async (): Promise<void> => {
    const allDrafts = await draftsTable?.toArray();

    const draftsMap: Record<string, Draft> = {};
    allDrafts?.forEach(draft => {
      draftsMap[draft.patientId] = {
        patientId: draft.patientId,
        text: draft.text,
        createdAt: draft.createdAt,
      };
    });
    setDrafts(draftsMap);
  }, [draftsTable]);

  const saveDraft = useCallback(
    async (draft: Draft): Promise<void> => {
      try {
        await draftsTable?.put(draft);
        console.log(`Bozza per il paziente ${draft.patientId} salvata con successo.`);
      } catch (error) {
        console.error(`Errore durante il salvataggio della bozza:`, error);
      }
    },
    [draftsTable]
  );

  const deleteDraft = useCallback(
    async (patientId: string): Promise<void> => {
      try {
        await draftsTable?.delete(patientId);
        console.log(`Bozza per il paziente ${patientId} cancellata con successo.`);
      } catch (error) {
        console.error(`Errore durante la cancellazione della bozza:`, error);
      }
    },
    [draftsTable]
  );

  useEffect(() => {
    if (userInfo && userInfo.salt && userInfo.id) {
      draftsTable = new SecureDatabase(userInfo.id, userInfo.salt, userInfo.id);
      loadDrafts();
    }
  }, [userInfo]);

  const value = useMemo(() => ({ drafts, saveDraft, loadDrafts, deleteDraft, getDraft }), [drafts]);

  return <MedicalDBContext.Provider value={value}>{children}</MedicalDBContext.Provider>;
};

export const useDrafts = (): MedicalDBContextType => useContext(MedicalDBContext);
