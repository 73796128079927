import Dexie, { IndexableType } from 'dexie';

export interface DraftMetadata {
  patientId: string;
  text: string;
  createdAt: Date;
}

interface EncryptedDraftMetadata {
  patientId: string;
  cipherText: Uint8Array;
  iv: Uint8Array;
}

interface Schema {
  [table: string]: string;
}

const dbSchema: Schema = {
  drafts: 'patientId, text, createdAt',
};

const encryptData = async (
  key: CryptoKey,
  data: DraftMetadata
): Promise<{ cipherText: Uint8Array; iv: Uint8Array }> => {
  const encoder = new TextEncoder();
  const iv = crypto.getRandomValues(new Uint8Array(12)); // 12-byte IV for AES-GCM
  const encodedData = encoder.encode(JSON.stringify(data));
  const encrypted = await crypto.subtle.encrypt({ name: 'AES-GCM', iv }, key, encodedData);

  return { cipherText: new Uint8Array(encrypted), iv };
};

const decryptData = async (key: CryptoKey, cipherText: Uint8Array, iv: Uint8Array): Promise<DraftMetadata> => {
  const decrypted = await crypto.subtle.decrypt({ name: 'AES-GCM', iv }, key, cipherText);

  return JSON.parse(new TextDecoder().decode(decrypted));
};

export class SecureDatabase {
  private db: Dexie;

  private password: string;

  private salt: Uint8Array;

  private drafts: Dexie.Table<EncryptedDraftMetadata, string>;

  constructor(password: string, salt: string, userId: string) {
    this.db = new Dexie(`MedicalDB__${userId}`);
    this.db.version(1).stores(dbSchema);
    this.drafts = this.db.table('drafts');
    this.password = password;
    this.salt = new TextEncoder().encode(salt);
  }

  deriveKey = async () => {
    const enc = new TextEncoder();
    const keyMaterial = await window.crypto.subtle.importKey(
      'raw',
      enc.encode(this.password),
      { name: 'PBKDF2' },
      false,
      ['deriveKey']
    );

    return window.crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt: this.salt,
        iterations: 100000, // Numero alto per sicurezza
        hash: 'SHA-256',
      },
      keyMaterial,
      { name: 'AES-GCM', length: 256 },
      true,
      ['encrypt', 'decrypt']
    );
  };

  // Example of storing in IndexedDB
  storeEncryptedData = async (draft: DraftMetadata): Promise<IndexableType> => {
    const key = await this.deriveKey();
    const { cipherText, iv } = await encryptData(key, draft);

    return this.drafts.put({ patientId: draft.patientId, cipherText, iv });
  };

  // Example of retrieving from IndexedDB
  retrieveEncryptedData = async (patientId: string): Promise<DraftMetadata | null> => {
    const record = await this.drafts.get(patientId);

    if (record) {
      const key = await this.deriveKey();

      return (await decryptData(key, record.cipherText, record.iv)) as DraftMetadata;
    }

    return null;
  };

  async get(key: string): Promise<DraftMetadata | null> {
    try {
      return await this.retrieveEncryptedData(key);
    } catch (error) {
      console.error('Error retrieving data:', error);
      throw error;
    }
  }

  async put(data: DraftMetadata): Promise<IndexableType> {
    try {
      return await this.storeEncryptedData(data);
    } catch (error) {
      console.error('Error putting data:', error);
      throw error;
    }
  }

  async toArray(): Promise<DraftMetadata[]> {
    const key = await this.deriveKey();

    try {
      const list = await this.drafts.toArray();
      const promises = list.map(value => decryptData(key, value.cipherText, value.iv) as Promise<DraftMetadata>);

      return Promise.all(promises);
    } catch (error) {
      console.error('Error converting data to array:', error);
      throw error;
    }
  }

  async delete(key: string): Promise<void> {
    try {
      await this.drafts.delete(key);
    } catch (error) {
      console.error('Error deleting data:', error);
      throw error;
    }
  }
}
