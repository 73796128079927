import { NotificationType, useNotifications } from '@lib/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { attachmentsApi } from '../api';

import { i18n } from 'i18n';
import { updateAttachmentByFileId } from 'store/attachments/actionCreators';
import { getAttachmentsToUpload } from 'store/attachments/selectors';

type UseAiHeaderExtractionSubscription = (onAiHeaderExtractionFinish: VoidFunction) => void;

export const useAiHeaderExtractionSubscription: UseAiHeaderExtractionSubscription = onAiHeaderExtractionFinish => {
  const dispatch = useDispatch();
  const { showNotification } = useNotifications();
  const attachmentsToUpload = useSelector(getAttachmentsToUpload);
  const [aiFilesToProcessCount, setAiFilesToProcessCount] = useState(
    () => attachmentsToUpload?.reduce((acc, { isPdfDataExtractable }) => (isPdfDataExtractable ? acc + 1 : acc), 0) || 0
  );

  attachmentsApi.usePdfHeaderExtracted({
    onData: ({ data: { data } }) => {
      if (data?.pdfHeaderExtracted) {
        dispatch(
          updateAttachmentByFileId({
            fileId: data.pdfHeaderExtracted.fileId,
            newParameters: { aiData: data.pdfHeaderExtracted },
          })
        );
        setAiFilesToProcessCount(prevCount => prevCount - 1);
      }
    },
    onError: (): void => {
      showNotification(i18n.t('features.medicalData.aindoFileParameters.extractionError'), NotificationType.WARNING);
      onAiHeaderExtractionFinish();
    },
    onSubscriptionComplete: onAiHeaderExtractionFinish,
  });

  useEffect(() => {
    if (!aiFilesToProcessCount) {
      onAiHeaderExtractionFinish();
    }
  }, [aiFilesToProcessCount]);
};
