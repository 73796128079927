import { ActionType } from 'typesafe-actions';

import * as actions from './actionCreators';

import { FileTag, FileType, FileUploadResultSuggestionModel, Maybe } from '__generated__/types';
import { PdfHeaderExtracted } from 'features/Attachments/api/graphql/__generated__/PdfHeaderExtracted.subscription';
import { YesNo } from 'store/attachments/enums';

export interface IState {
  attachmentsToUpload: IAttachmentToUpload[] | null;
}

export interface IAttachmentToUpload {
  fileId: string;
  fileExtensions: string;
  file: File;
  userId: string;
  fileType: FileType;
  textToAnalyze?: Maybe<string>;
  label: string;
  dateOfExecution: Maybe<Date>;
  fileTag?: Maybe<FileTag>;
  isFilePrivate: boolean;
  patientOwnershipDecision?: Maybe<YesNo>;
  isPdfDataExtractable?: boolean;
  aiData?: Omit<PdfHeaderExtracted['pdfHeaderExtracted'], '__typename'>;
  hasErrors?: boolean;
  aiSuggestions?: FileUploadResultSuggestionModel;
}

export enum ActionTypeList {
  SET_ATTACHMENTS_TO_UPLOAD = '@@attachments/SET_ATTACHMENTS_TO_UPLOAD',
  REMOVE_ATTACHMENTS_TO_UPLOAD = '@@attachments/REMOVE_FILE_LIST_BY_TAGS',

  ADD_ATTACHMENT_TO_LIST = '@@attachments/ADD_ATTACHMENT_TO_LIST',
  REMOVE_ATTACHMENT_FROM_LIST = '@@attachments/REMOVE_ATTACHMENT_FROM_LIST',

  ADD_ATTACHMENTS_TO_LIST = '@@attachments/ADD_ATTACHMENTS_TO_LIST',

  UPDATE_ATTACHMENT_IN_LIST = '@@attachments/UPDATE_ATTACHMENT_IN_LIST',
  UPDATE_ATTACHMENT_BY_FILE_ID = '@@attachments/UPDATE_ATTACHMENT_BY_FILE_ID',
}

export type Action = ActionType<typeof actions>;

export const initialState: IState = {
  attachmentsToUpload: null,
};
