import { CloseIcon, MaterialSymbolsIcon } from '@lib/icons';
import { getDefaultBorder } from '@lib/react-components';
import { DASH_EMPTY_VALUE, getDateStringWithoutTz } from '@lib/utils';
import { Box, Stack, Typography, useTheme } from '@mui/material';

import { BaseFileDataSection } from './BaseFileDataSection';
import { useUploadFileItem } from './hooks';

import { FileTag, Maybe } from '__generated__/types';
import { useActiveProfileContext } from 'features/ActiveProfile';
import { useFileClickRedirect } from 'features/Files';
import { i18n } from 'i18n';
import { IAttachmentToUpload } from 'store/attachments/types';

interface IUploadFileItem {
  attachment: IAttachmentToUpload;
  index: number;
  totalAttachmentFiles?: number;
  // Section, preselected by user on the medical data folder
  userPreselectedTag?: string;
  currentPatientFullName: string;
  dateOfExecution: Maybe<Date>;
}

export const UploadFileItem = ({
  index,
  userPreselectedTag,
  currentPatientFullName,
  totalAttachmentFiles = 0,
  dateOfExecution,
  attachment: { file, isFilePrivate, aiData, fileId, label },
}: IUploadFileItem): JSX.Element => {
  const { palette } = useTheme();
  const { isPatient } = useActiveProfileContext();
  const { onFileClickRedirect } = useFileClickRedirect();

  const preselectedTag = (userPreselectedTag as FileTag) || aiData?.fileTag;
  const isCurrentPatientFullNameExist = currentPatientFullName !== DASH_EMPTY_VALUE;
  const initialExecutionDate = aiData?.executionDate
    ? new Date(getDateStringWithoutTz(aiData.executionDate))
    : dateOfExecution;
  const isPatientPrivateFile = isFilePrivate && isPatient;

  const {
    fileName,
    fileDateOfExecution,
    fileTag,
    handleFileNameChange,
    handleFileTagChange,
    handleFileDateOfExecutionChange,
    handleIsFilePrivateChange,
    removeFile,
    fileNameErrorText,
    fileTagErrorText,
    fileDateOfExecutionErrorText,
  } = useUploadFileItem({
    index,
    initialValues: {
      fileName: label,
      dateOfExecution: initialExecutionDate,
      fileTag: isCurrentPatientFullNameExist ? preselectedTag : FileTag.PATIENT_UNRELATED_DOCUMENT,
    },
  });

  const onFileRedirect: VoidFunction = () => {
    onFileClickRedirect(fileId, true);
  };

  const totalAttachmentFilesTitle =
    totalAttachmentFiles > 1
      ? i18n.t('features.attachments.uploadFiles.totalFiles', {
          index: index + 1,
          total: totalAttachmentFiles,
        })
      : '';

  return (
    <Box
      sx={{
        overflow: 'hidden',
        borderRadius: '10px',
        border: getDefaultBorder(palette.lines.main),
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          padding: '6px 24px',
          justifyContent: 'space-between',
          backgroundColor: palette.surface.generic,
        }}
      >
        <Typography>{totalAttachmentFilesTitle}</Typography>
        <MaterialSymbolsIcon sx={{ cursor: 'pointer' }} icon={<CloseIcon fontWeight="300" />} onClick={removeFile} />
      </Stack>
      <Stack sx={{ gap: '32px', padding: ' 16px 24px 20px' }}>
        <BaseFileDataSection
          isPatientPrivateFile={isPatientPrivateFile}
          onFileRedirect={onFileRedirect}
          fileTag={fileTag as FileTag}
          aiFileTag={aiData?.fileTag}
          originalFileName={file.name}
          executionDate={fileDateOfExecution}
          fileName={fileName}
          fileNameErrorText={fileNameErrorText}
          fileTagErrorText={fileTagErrorText}
          fileDateOfExecutionErrorText={fileDateOfExecutionErrorText}
          handleFileNameChange={(event): void => handleFileNameChange(event.target.value)}
          handleExecutionDateChange={(value): void => handleFileDateOfExecutionChange(value)}
          handleFileTagChange={(value): void => handleFileTagChange(value)}
          handleFileNameBlur={(event): void => handleFileNameChange(event.target.value)}
          handleFileTagBlur={(event): void => handleFileTagChange(event.target.value as FileTag)}
          handleDateOfExecutionBlur={(): void => handleFileDateOfExecutionChange(fileDateOfExecution)}
          handleIsFilePrivateChange={(value): void => handleIsFilePrivateChange(value)}
          withTagsSelect={isCurrentPatientFullNameExist}
        />
      </Stack>
    </Box>
  );
};
