import { useUploadFile } from '@lib/features-bll';
import { getFileTags } from '@lib/features-ui';
import { getIsoFormatUTCDate } from '@lib/utils';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { IDocument } from '../../../model/useDocumentsStore';

import { FileType, Maybe } from '__generated__/types';
import { useActiveProfileContext } from 'features/ActiveProfile';
import { performOCRonPDF } from 'features/Files/model/usePerformOCRonPDF';
import { useUserModelStore } from 'features/Users/model';
import { escapeFile } from 'lib/helpers/fileList';
import { useIsMobileAppWebView } from 'lib/hooks/useIsMobileAppWebView';
import { addAttachmentsToList } from 'store/attachments/actionCreators';
import { IAttachmentToUpload } from 'store/attachments/types';

interface IUseUploadAttachmentsProps {
  patientPortalUserId?: string;
  preselectedTag?: string;
  onComplete?: (attachments: IAttachmentToUpload[]) => void;
}

interface IUseUploadAttachmentsReturn {
  uploadAttachments: (files: Maybe<FileList>, params?: IDocument[]) => Promise<void>;
  isUploadAttachmentsLoading?: boolean;
  reset: VoidFunction;
  setIsLoading: (isLoading: boolean) => void;
}

type UseUploadAttachments = (props: IUseUploadAttachmentsProps) => IUseUploadAttachmentsReturn;

export const useUploadAttachments: UseUploadAttachments = ({ patientPortalUserId, preselectedTag, onComplete }) => {
  const { userInfo } = useUserModelStore();
  const { isPatient } = useActiveProfileContext();
  const [isLoading, setIsLoading] = useState<boolean | undefined>();
  const dispatch = useDispatch();
  const [uploadFile] = useUploadFile();

  const isMobileAppWebView = useIsMobileAppWebView();

  const uploadAttachments: IUseUploadAttachmentsReturn['uploadAttachments'] = async (files, params) => {
    if (!files) {
      return;
    }

    setIsLoading(true);

    const attachments = await Promise.all(
      Array.from(files).map(async file => {
        const paramFound = params?.find(param => param.label === file.name);
        const arrayBuffer = await file.arrayBuffer();
        // NOTE app used paramFound yet, so we don't need this performedTextFound
        const performedTextFound = isMobileAppWebView ? null : await performOCRonPDF(arrayBuffer);

        const attachment: IAttachmentToUpload = {
          file,
          fileExtensions: file.type,
          fileId: '',
          label: file.name,
          textToAnalyze: performedTextFound ?? paramFound?.text ?? null,
          dateOfExecution: null,
          fileTag: getFileTags({ isPatient }).find((tag): boolean => tag.value === preselectedTag)?.value,
          userId: userInfo?.id || '',
          fileType: FileType.MEDICAL_PARAMETER,
          isFilePrivate: false,
          hasErrors: false,
        };
        const { data, errors } = await uploadFile({
          variables: {
            dateOfExecution: getIsoFormatUTCDate(attachment.dateOfExecution || new Date()),
            file: escapeFile(attachment.file),
            fileType: attachment.fileType,
            label: attachment.label || attachment.file.name,
            userId: attachment.userId,
            patientPortalUserId,
            isCancelled: attachment.isFilePrivate,
            textToAnalyze: attachment.textToAnalyze,
          },
        });

        const suggestedDateOfExecution = data?.uploadFile.suggestions.dateOfExecution
          ? new Date(data.uploadFile.suggestions.dateOfExecution)
          : null;

        return {
          ...attachment,
          fileId: data?.uploadFile.id ?? '',
          isPdfDataExtractable: data?.uploadFile.isPdfDataExtractable,
          hasErrors: !!errors,
          label: data?.uploadFile.suggestions.label ?? attachment.label ?? attachment.file.name,
          fileTag: attachment.fileTag || data?.uploadFile.suggestions.tag,
          dateOfExecution: attachment.dateOfExecution || suggestedDateOfExecution,
          aiSuggestions: data?.uploadFile.suggestions,
        };
      })
    );

    const validAttachments = attachments.filter(attachment => !attachment.hasErrors);

    dispatch(addAttachmentsToList(validAttachments));
    onComplete?.(validAttachments);

    setIsLoading(false);
  };

  const reset: IUseUploadAttachmentsReturn['reset'] = () => setIsLoading(undefined);

  return { uploadAttachments, isUploadAttachmentsLoading: isLoading, reset, setIsLoading };
};
