import { useAttachPatientFile } from '@lib/features-bll';

import { useCancelFile } from './graphql/__generated__/CancelFile.mutation';
import { useGenerateAndSavePdfPatientFile } from './graphql/__generated__/GenerateAndSavePdfPatientFile.mutation';
import { usePdfHeaderExtracted } from './graphql/__generated__/PdfHeaderExtracted.subscription';
import { usePdfParametersExtracted } from './graphql/__generated__/PdfParametersExtracted.subscription';
import { useRestoreFile } from './graphql/__generated__/RestoreFile.mutation';
import { useUpdatePatientFileReportPrescription } from './graphql/__generated__/UpdatePatientFileReportPrescription.mutation';

export const attachmentsApi = {
  useGenerateAndSavePdfPatientFile,
  useAttachPatientFile,
  useCancelFile,
  useRestoreFile,
  usePdfHeaderExtracted,
  usePdfParametersExtracted,
  useUpdatePatientFileReportPrescription,
};

export type { UpdatePatientFileReportPrescriptionVariables } from './graphql/__generated__/UpdatePatientFileReportPrescription.mutation';
