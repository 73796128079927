import { getFormatDateWithoutTz, getIsoFormatUTCDate } from '@lib/utils';

import { FileTag, FileType } from '__generated__/types';
import { useActiveProfileContext } from 'features/ActiveProfile';
import { useAttachmentsStore } from 'features/Attachments/model';
import { useUserModelStore } from 'features/Users/model';
import { IUpdateReportPrescriptionDataInStorageVariables } from 'graphql/mutations/consultations/types';
import { RequestAttachmentFile } from 'graphql/types/request';
import { getRandomId } from 'lib/helpers';

interface IUseUpdateReportPrescriptionDataInStoreReturn {
  updateReportPrescriptionDataInStore: (params: {
    variables: IUpdateReportPrescriptionDataInStorageVariables;
    options?: { onError?: VoidFunction; onSuccess?: VoidFunction };
  }) => void;
}

type UseUpdateReportPrescriptionDataInStore = () => IUseUpdateReportPrescriptionDataInStoreReturn;

export const useUpdateReportPrescriptionDataInStore: UseUpdateReportPrescriptionDataInStore = () => {
  const { updateAttachment } = useAttachmentsStore();
  const { userInfo } = useUserModelStore();
  const { activeProfile } = useActiveProfileContext();

  const uploaderInstitutionUser = {
    id: activeProfile?.id,
    profileType: activeProfile?.profileType,
    user: {
      firstName: userInfo?.firstName || '',
      lastName: userInfo?.lastName || '',
    },
  };

  const updateReportPrescriptionDataInStore: IUseUpdateReportPrescriptionDataInStoreReturn['updateReportPrescriptionDataInStore'] =
    ({ variables, options }) => {
      updateAttachment(
        {
          file: {
            dateOfExecution: getIsoFormatUTCDate(new Date()),
            id: getRandomId(),
            label: getFormatDateWithoutTz(new Date()),
            tag: variables.fileType as unknown as FileTag,
            type: variables.fileType as unknown as FileType,
            uploaderInstitutionUser,
          } as RequestAttachmentFile,
          isServiceAttaches: true,
          isDownloadable: false,
          id: variables.pdfDataId || '',
          type: variables.fileType,
          data: variables,
        },
        variables.pdfDataId || ''
      );

      options?.onSuccess?.();
    };

  return { updateReportPrescriptionDataInStore };
};
