import { useModalState } from '@lib/react-components';

import { AiHeaderDataExtractionLoadingModal } from './AiHeaderDataExtractionLoadingModal';
import { AiParamsDataExtractionModals } from './AiParamsDataExtractionModals';

import { Maybe } from '__generated__/types';
import { attachmentsModel } from 'features/Attachments/model';

interface IAiProcessingModalsProps {
  isAiParamsDataExtractionModalsVisible?: boolean;
  patientId?: Maybe<string>;
  onAiHeaderFinish: VoidFunction;
  onAiParamsFinish: VoidFunction;
}

export const AiProcessingModals = ({
  isAiParamsDataExtractionModalsVisible,
  patientId,
  onAiHeaderFinish,
  onAiParamsFinish,
}: IAiProcessingModalsProps): JSX.Element => {
  const [isAiHeaderDataExtractionLoadingModalOpen, , closeAiHeaderDataExtractionLoadingModalOpen] = useModalState(true);

  attachmentsModel.useAiParamsExtractionSubscription(onAiParamsFinish);

  return (
    <>
      {isAiHeaderDataExtractionLoadingModalOpen && (
        <AiHeaderDataExtractionLoadingModal
          onFinish={(): void => {
            closeAiHeaderDataExtractionLoadingModalOpen();
            onAiHeaderFinish();
          }}
        />
      )}
      {isAiParamsDataExtractionModalsVisible && (
        <AiParamsDataExtractionModals patientId={patientId} onCancel={onAiParamsFinish} />
      )}
    </>
  );
};
